.app__footer {
    height: 10vh;
    width: 100%;
  
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    background: var(--primary-color);
    user-select: none;
    color: var(--grey-color);
}