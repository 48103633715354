@import url("https://fonts.googleapis.com/css?family=Oswald");

/* 
  Fonts Lib

  Oswald https://fonts.google.com/specimen/Oswald
  Bitter https://fonts.google.com/specimen/Bitter
  Lora https://fonts.google.com/specimen/Lora
  Play Fair https://fonts.google.com/specimen/Playfair+Display
*/
:root {
  --font-base: "Oswald", sans-serif;
  
  --primary-color: #000000;
  --dark-grey-color: #1d1d1d;
  --secondary-color: #ddb361;
  --third-color: #d8a94c;
  --forth-color: #c6922b;

  --white-color: white;
  --grey-color:  #C0C0C0;
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  background-clip: content-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}