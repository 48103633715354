.app {
  background-color: var(--dark-grey-color);
  font-family: var(--font-base);
}

.p-text {
  font-size: 1.2rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
}

.bold-text {
  font-weight: 700;
  color: var(--secondary-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}