.app__contact {
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-wrap: wrap;
    color: var(--dark-grey-color);
}
 
.app__footer-cards {
    margin-top: 10rem;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--secondary-color);

    transition: all 0.3s ease-in-out;
}

.app__footer-card > a:hover {
    color: var(--dark-grey-color);
}

.app__footer-card > img {
    width: 40px;
    height: 40px;
    margin: 0 0.7rem;
}

.app__footer-card > p {
      font-weight: 600;
}

.app__footer-card > a {
    text-decoration: none;
    font-weight: 600;
}
.app__footer-card:hover {
    box-shadow: 0 0 10px var(--secondary-color);
}
  
.app__footer-form {
    width: 60%;
    min-width: 290px;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 5rem;
}
.app__footer-form > div {
    width: 100%;
    justify-content: flex-start;
    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--secondary-color);

    transition: all 0.3s ease-in-out;
}

.app__footer-form > div > input,
.app__footer-form > div > textarea {
    width: 100%;
    padding-left: .5rem;
    border: none;
    border-radius: 10px;
    background-color: var(--secondary-color);
    color: var(--white-color);
    outline: none;
}

.app__footer-form > div > input::placeholder,
.app__footer-form > div > textarea::placeholder {
    color: var(--white-color);
}

.app__footer-form > div > textarea {
    height: 170px;
}

.app__footer-form > button {
    padding: 1rem 2rem;
    border-radius: 25px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 600;
    color: var(--dark-grey-color);
    outline: none;
    margin: 2rem 0 0 0;

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53) all;
    cursor: pointer;

    transition: color .4s ease;
    transition: border-radius .4s ease;;
}

.app__footer-form > button:hover{
    background-color: var(--grey-color);
    border-radius: 10px;
}