.navbar{
  background: var(--primary-color);
  z-index: 1000;
  user-select: none;
} 

@media (max-width: 100px) {
  .navbar-brand > {
    width: 50px;
  }
}

#navbarSupportedContent > ul > li:nth-child(n) > a{
  color: var(--secondary-color);
  font-size: 1.2rem;
  padding: 0 0.8rem;
}

#navbarSupportedContent > ul > li:nth-child(n) > a {
  transition: color .4s ease;
}

#navbarSupportedContent > ul > li:nth-child(n) > a:hover,
#navbarSupportedContent > ul > li:nth-child(n) > a:active {
  color: var(--white-color);
}

#navbarSupportedContent > ul > li:nth-child(n) > div {
  transition: background .3s ease;
}

#navbarSupportedContent > ul > li:nth-child(n):hover div {
  background: var(--secondary-color);
}

#navbarSupportedContent > ul > li {
  padding: 0 .5rem;
  cursor: pointer;

  display: flex;

  align-items: center;
  justify-content: center;
  
  flex-direction: column;
}

#navbarSupportedContent > ul > li > div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: transparent;
  margin-bottom: 5px;
}

#navbarSupportedContent > a > button{
  background: var(--secondary-color);
  color: var(--dark-grey-color);
  font-size: 1.2rem;
  padding: .4rem 1.5rem;
  border-radius: 30px;
  transition: border-radius .4s ease;
  border-width: 0px;
}

#navbarSupportedContent > a > button:hover {
  background-color: var(--grey-color);
  border-radius: 12px;
  transition: border-radius .4s ease;
  color: var(--dark-grey-color);
}

.navbar_list {
  position: "absolute";
  left: "50%";
  transform: translateX(-15%);
}

.navbar_icon {
  color: var(--grey-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

@media (max-width: 991.98px) { /* target styles for when navbar is collapsed */
  .navbar_list .nav-item > div {
    display: none; /* hide empty div elements */
  }

  #navbarSupportedContent > ul > li {
    padding: 0 3.5rem;
    padding-top: .5rem;
    cursor: pointer;
  
    display: flex;
  
    align-items: normal;
    justify-content: left;
  }

  /* Last child */
  #navbarSupportedContent > ul > li:nth-child(3) {
    padding-bottom: .5rem;
  }
}