.app__gallery {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: var(--dark-grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-slideshow {
  height: 50%;
  width: 50%;
  object-fit: cover;
  overflow: hidden;
  border-color: var(--grey-color);
  border-radius: 10px;
  user-select: none;
  box-shadow: 0 0 5px 2px var(--grey-color);
  margin-top: 10rem;
}

.gallery-frames {
  height: 100%;
  min-width: 50%;

  margin: 2rem 5rem 5rem 5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  background-color: var(--primary-color);
  border-radius: 20px;
  padding: 3rem;
}

.frame {
  display: flex;
  flex: 0 0 30vmin;
  height: 20vmin;
  width: 30vmin;
  object-fit: cover;
  object-position: 50% 50%;
  border-color: var(--primary-color);
  border-radius: 20px;
  border-width: 2px;

  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: scale 0.5s ease;
}

.frame:hover {
  scale: 1.1;
}

.gallery-slideshow > .carousel > .carousel-indicators > button {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .gallery-slideshow {
    height: 80%;
    width: 80%;
  }
}

@media (max-width: 576px) {
  .gallery-slideshow {
    height: 80%;
    width: 80%;
  }
}
