.app__pricing {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

.background {
    display: flex;
    width: 100%;
    min-height: 100%;
    background-image: url("../../assets/about-background.jpg");
    filter: blur(8px);
    -webkit-filter: blur(8px);
    position: absolute;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

  .card-holder {
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5rem;
    min-height: 50%;
    width: 100%;
    align-items: center;
  }
  
  .card {
    max-width: 350px;
    gap: 5rem;
    background-color: var(--white-color);
  }

  .card > .card-body > ul > li {
    font-weight: 600;
    color: var(--dark-grey-color);
  }

  .card > .card-body > ul {
    display: inline-block;
    text-align: left;
  }

  .book-button > button{
    background: var(--secondary-color);
    color: var(--dark-grey-color);
    font-size: 1.2rem;
    padding: .4rem 1.5rem;
    border-radius: 30px;
    transition: border-radius .4s ease;
    border-width: 0px;
  }
.book-button > button:hover {
    background-color: var(--grey-color);
    border-radius: 12px;
    transition: border-radius .4s ease;
    color: var(--dark-grey-color);
  }
  