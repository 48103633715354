.app__home-content {
  display: flex;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.app__home-landing {
  width: 100%;
  min-height: 100vh;
  display: flex;

  color: var(--grey-color);
}

#home > .app__home-content {
  position: relative;
}

.video-container > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: flex-start;
  padding-left: 5rem;
}

.video-cover > .landing-text {
  text-align: left;
  color: var(--white-color);
  max-width: 50%;
}

.app__home-about {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  color: var(--grey-color);
}

#about > .app__home-content > .app__home-about-content {
  background-image: url("../../assets/about-background.jpg");
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Default width for mobile */

  @media (min-width: 768px) {
    width: 70%; /* Adjust the width for desktop or larger screens */
  }

  height: 100%;
}

.about-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding-left: 5rem;
}

.about-text > .about-div {
  max-width: 50%;
  color: var(--grey-color);
}

.app__home-about-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .about-text > .about-div {
    font-size: 15px;
    line-height: 1.4;
  }
}

@media (max-width: 576px) {
  .about-text > .about-div {
    font-size: 14px;
    line-height: 1.3;
  }
}

.app__home-gallery {
  width: 100%;
  min-height: 100vh;
  display: flex;
  user-select: none;
  color: var(--grey-color);
}

.app__home-gallery-content {
  width: 100%;
  margin-right: 5rem;
  height: 100%;
  display: flex;
  margin-left: 5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.gallery-title {
  color: var(--secondary-color);
}

#image-track > .image {
  flex: 0 0 56vmin;
  height: 40vmin;
  width: 56vmin;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
  border-color: var(--grey-color);
  border-radius: 10px;
  user-select: none;

  box-shadow: 0 0 5px 2px var(--grey-color);

  transition: object-position 0.1s ease;
}

#image-track {
  padding: 1rem;
  flex-wrap: wrap;
  display: flex;
  gap: 2.5rem;
  user-select: none;
  justify-content: center;

  transition: transform 0.1s ease;
}

#gallery > .app__home-content {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.image {
  max-width: 100%;
  display: none;
}

/* Add these styles to your CSS */
.thumbnail-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.thumbnail {
  width: 50px; /* Adjust the width and height as needed */
  height: 50px;
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
}

.thumbnail.active {
  border-color: var(
    --active-color
  ); /* Style the active thumbnail differently */
}

#timeline {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.timeline-dot {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--grey-color);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.timeline-dot:hover {
  background-color: var(--secondary-color);
}

.app__home-offers {
  width: 100%;
  min-height: 100vh;
  display: flex;

  background-image: url("../../assets/offers-background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.app__home-about-content {
  background-color: rgba(0, 0, 0, 0.2);
  padding-left: 5rem;
  align-items: start;
  flex-direction: column;
  text-align: end;
}

.offer-text {
  align-items: center;
  max-width: 50%;
  text-align: left;
}

@media (max-width: 768px) {
  .offer-text {
    max-width: 75%;
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .offer-text {
    max-width: 75%;
    font-size: 12px;
  }
}

.offer-div {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.offer-item {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: var(--grey-color);
  justify-content: flex-start;
}

.offer-item > div {
  align-items: center;
  justify-content: center;
  text-align: left;
  flex-direction: column;
}

.offer-item > img {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}
