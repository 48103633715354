/* WhatsAppButton.css */

#whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px; /* Position it on the right side */
  width: 50px; /* Adjust the size as needed */
  height: 50px;
  border-radius: 50%; /* Make it round */
  background-color: #25d366; /* WhatsApp green color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

/* Rest of the styles remain the same */

#whatsapp-button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

#whatsapp-button img {
  width: 70%;
  height: auto;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
  #whatsapp-button {
    width: 70px; /* Adjust the size for mobile */
    height: 70px;
    right: 20px; /* Adjust the position */
  }

  #whatsapp-button img {
    width: 70%;
    height: auto; /* Adjust the logo size for mobile and ensure it fits the green circle */
  }
}
